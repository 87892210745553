import React from 'react';

const SuspenseLoading = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="font-poppins text-center text-black text-opacity-100">
        <span className='font-poppins text-md text-black animate-fadeIn inline'>
          Loading<p className="text-4xl font-semibold animate-pulse inline"> . . .</p>
        </span>
      </div>
    </div>
  )
}

export default SuspenseLoading;