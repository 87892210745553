//Triggering Build
import { notification } from "antd";
import axios from "axios";

//Production URL
export const baseURL = "https://providerapi.mywwsbm.com/";
//Testing URL
// export const baseURL = "https://testingbmapi.mywwsbm.com/";
//Local URL
// export const baseURL = 'http://localhost:5000/';

export const idealLogOutTime = 1000 * 60 * 30; // when user inactivity automatically log out timer 30 minutes

const instance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.response.use(
  (response) => response, // Pass successful responses as is
  (error) => {
    const status = error?.response?.status;
    // Extract message safely with fallback defaults
    const message = error?.response?.data?.message ||
      error?.response?.data ||
      "An error occurred";

    if (status === 401) {
      // Unauthorized: Log out the user
      localStorage.setItem('isLoggedOut', 'true');
    } else if (status === 403) {
      // Forbidden: Show an error notification

    } else if (status === 400) {
      // Bad Request: Log for debugging and show notification
      notification.error({
        message: 'Bad Request',
        description: message,
        placement: 'topRight',
        duration: 3,
      });
      // Bad Request: Log for debugging

    } else if (status === 429) {
      // Too Many Requests: Notify the user

    }

    // Return the error to the calling code
    return Promise.reject(error);
  }
);


export default instance;