// Async thunk for fetching charts
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSubsequentCharts } from '../../api/chart/chart';

export const subsequentChartApi = createAsyncThunk(
  'useCharts/fetchSubsequentCharts',
  async ({ batchNo, page = 1, limit = 5, date = null }, { rejectWithValue }) => {
    try {
      const response = await getSubsequentCharts({ batchNo, page, limit, date });
      return response.data;
    } catch (error) {
      console.error('Error fetching charts:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);