import api from "../../api";

const baseUrl = "/api/role";

export const getRoles = () => {
  return api.get(baseUrl);
};

export const addRole = (postData) => {
  return api.post(baseUrl, postData, { withCredentials: true });
};

export const updateRole = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteRole = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`, { withCredentials: true });
};
