import api from "../../api";

const baseUrl = "/api/chart";


export const getCharts = () => {
  return api.get(baseUrl);
};

// export const getTodayCharts = () => {
//   return api.get(`${baseUrl}/getTodayCharts`);
// };

export const getTodayCharts = () => {
  return api.get(`${baseUrl}/getTodayCharts`, { withCredentials: true });
};

export const getSubsequentCharts = ({ batchNo, page = 1, limit = 5, project, date }) => {
  const params = { batchNo, page, limit, project, date }; // Construct parameters
  return api.get(`${baseUrl}/subsequentCharts`, { params, withCredentials: true });
};

//For Repo

export const getBatchWiseChartsApi = (batchNo) => {
  return api.get(`${baseUrl}/charts`, {
    params: {
      batchNo
    },
    withCredentials: true, // Ensure it is inside the second objectF
  });
};

export const getFilteredCharts = (chartFilters, searchTerm, projectId) => {
  return api.get(`${baseUrl}/filteredCharts`, {
    params: {
      chartFilters,
      search: searchTerm,
      projectId
    },
    withCredentials: true, // Ensure it is inside the second object
  });
};

// export const getFilteredCharts = (chartFilters, searchTerm, projectId) => {
//   return api.get(`${baseUrl}/filteredCharts`, {
//     params: {
//       chartFilters,
//       search: searchTerm,
//       projectId
//     },
//   }, { withCredentials: true });
//   // return api.get(`${baseUrl}/filteredCharts`, {
//   //   params: {
//   //     chartFilters,
//   //     search: searchTerm,
//   //     projectId
//   //   },
//   // });
// };

export const getReportScheduleData = (chartFilters) => {
  return api.get(`${baseUrl}/reportRepository`, {
    params: {
      chartFilters,
      // search: searchTerm,
      // projectId
    },
    withCredentials: true, // Ensure it is inside the second object
  });
}

export const getPaginatedProjectWiseChartsApi = (page, limit, projectId, searchTerm) => {
  return api.get(`${baseUrl}/projectCharts`, {
    params: {
      page,
      limit,
      projectId,
      search: searchTerm,
    },
    withCredentials: true, // Ensure it is inside the second objectF
  });
};

export const getPaginatedUserChartsApi = (page, limit, userId, searchTerm, role) => {
  return api.get(`${baseUrl}/userCharts`, {
    params: {
      page,
      limit,
      userId,
      role,
      search: searchTerm,
    },
    withCredentials: true, // Ensures credentials are sent with the request
  });
};


export const getPaginatedUserLogChartsApi = (
  page,
  limit,
  userId,
  searchTerm,
  chartFilters,
  selectedDropFields
) => {
  return api.get(`${baseUrl}/userLogCharts`, {
    params: {
      page,
      limit,
      userId,
      search: searchTerm,
      chartFilters,
      selectedDropFields,
    },
    withCredentials: true, // Ensures credentials are sent with the request
  });
};


export const getAssignedBatchesNo = (id, project, role) => {
  return api.get(`${baseUrl}/${id}`, {
    params: {
      project,
      role
    },
    withCredentials: true, // Ensures credentials are sent with the request
  });
};


export const getAssignedSubsequentBatchesNo = (id, project, role) => {
  return api.get(`${baseUrl}/subsequent/${id}`, {
    params: {
      project,
      role
    }
  });
};

export const getChartsByBatch = (id, batchNo, role, isSubsequentConfirmPage) => {
  return api.get(`${baseUrl}/getChartsByBatch/${id}`, {
    params: {
      batchNo,
      role,
      isSubsequentConfirmPage,
    },
    withCredentials: true
  });
};

export const getSubsequentChartsByBatch = (id, batchNo, role) => {
  return api.get(`${baseUrl}/getSubsequentChartsByBatch/${id}`, {
    params: {
      batchNo,
      role
    }
  });
};

export const getDuplicateCharts = (patientName, dob) => {
  return api.post(`${baseUrl}/getDuplicateCharts`, {
    patientName,
    dob
  });
};

export const viewChart = (id) => {
  return api.get(`${baseUrl}/${id}`);
};

export const createChart = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateChart = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData, { withCredentials: true });
};

export const deleteChart = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};
