// src/redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: {
    userId: null,
    role: null,
    project: null,
    userProject: null,
    batchNo: null,
    currentChartId: null,
    wholeBatchNo: null,
    userAccess: null,
    timeZone: null,
    firstName: null,
    lastName: null,
    projectName: null,
    avatarKey: null,
    avatarUrl: null,
    emailId: null,
    userProjectAccess: null,
    isFromSubsequent: false
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.project = action.payload.project;
      state.userProject = action.payload.userProject;
      state.batchNo = action.payload.batchNo;
      state.currentChartId = action.payload.currentChartId;
      state.wholeBatchNo = action.payload.wholeBatchNo;
      state.userAccess = action.payload.userAccess;
      state.timeZone = action.payload.timeZone;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.projectName = action.payload.projectName;
      state.avatarKey = action.payload.avatarKey
      state.avatarUrl = action.payload.avatarUrl
      state.emailId = action.payload.emailId
      state.userProjectAccess = action.payload.userProjectAccess
    },
    setIsFromSubsequent: (state, action) => {
      state.isFromSubsequent = action.payload
    },
    clearUserDetails: (state) => {
      state.userId = null;
      state.role = null;
      state.project = null;
      state.userProject = null;
      state.batchNo = null;
      state.currentChartId = null;
      state.wholeBatchNo = null;
      state.userAccess = null;
      state.timeZone = null;
      state.firstName = null;
      state.lastName = null;
      state.projectName = null;
      state.avatarKey = null;
      state.avatarUrl = null;
      state.emailId = null;
      state.userProjectAccess = null;
    },
  },
});

export const { setUserDetails, clearUserDetails, setIsFromSubsequent } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
