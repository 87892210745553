import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import loadingReducer from './loading/loadingSlice';
import projectReducer, { projectListenerMiddleware } from './project/projectSlice';
import roleReducer, { roleListenerMiddleWare } from './role/roleSlice';
import userReducer from './user/userSlice';
import documentReducer from './document/documentSlice';
import chartReducer from "./chart/chartSlice";
import useCharReducer from "./useCharts/useChartsSlice"
import subsequentDocumentReducer from "./subsequentDocument/subsequentDocumentSlice"



import notificationReducer from "./notification/notificationSlice";
import chartDocumentReducer from "./chartDocument/chartDocumentSlice";
import scheduleSliceReducer from './scheduleSend/scheduleSendSlice';
import userDetailsReducer from './userDetails/userDetailsSlice';
import providerReducer from './provider/providerSlice';
import exclusionReducer from "./Exclusion/exclusionSlice";
import timeZoneReducer from "./timeZone/timeZoneSlice";
import reportRepositoryReducer from "./ReportRepository/reportRepositorySlice"
import recentHistorySliceReducer from './recentHistory/recentHistorySlice';
import chartViewManagementReducer from './ChartViewManagement/ChartViewManagementSlice';
import authReducer from './auth/authSlice';




// Function to generate a unique key and store it in sessionStorage
const getPersistKey = () => {
  const persistKey = sessionStorage.getItem('persistKey');
  if (!persistKey) {
    const newPersistKey = `persistedState-${new Date().getTime()}`;
    sessionStorage.setItem('persistKey', newPersistKey);
    return newPersistKey;
  }
  return persistKey;
};

const persistConfig = {
  key: getPersistKey(),
  version: 1,
  storage,
  // blacklist is for rehydrate
  blacklist: ['loading', 'notification', 'chartDocument', 'scheduleSend', 'chart', 'document', 'project', 'role', 'user', 'exclusion', 'timeZone', 'listenerActive'], // Add slices that don’t need persistence
  // whitelist is for hydrate
  whitelist: ['userDetails', 'provider'],
};

const rootReducer = combineReducers({
  loading: loadingReducer,
  project: projectReducer,
  role: roleReducer,
  user: userReducer,
  document: documentReducer,
  chart: chartReducer,
  notification: notificationReducer,
  chartDocument: chartDocumentReducer,
  scheduleSend: scheduleSliceReducer,
  userDetails: userDetailsReducer,
  provider: providerReducer,
  exclusion: exclusionReducer,
  timeZone: timeZoneReducer,
  reportRepository: reportRepositoryReducer,
  recentHistory: recentHistorySliceReducer,
  chartViewManagement: chartViewManagementReducer,
  auth: authReducer,
  useCharts: useCharReducer,
  subsequentDocument: subsequentDocumentReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,

    }).prepend(projectListenerMiddleware.middleware, roleListenerMiddleWare.middleware), // Register middleware
});

export const persistor = persistStore(store);
