import React, { useEffect, useMemo, useState } from "react";
import { Tabs } from 'antd';
import { useNavigate } from "react-router-dom";
import ProviderUser from "../ProviderUser/ProviderUser";
import Role from "../Role/Role";


const UserDirectoryTabs = ({ setTabName }) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const navigate = useNavigate();


  const tabNames = useMemo(() => ({
    "1": "Users",
    "2": "Role",
  }), []);


  useEffect(() => {
    // Set the initial tab and breadcrumb on the first render
    const initialTabName = tabNames[activeTabKey];
    setTabName(initialTabName); // Initial breadcrumb
    navigate(`/provider/user-directory/${activeTabKey}`, { replace: true }); // Default tab route
  }, [activeTabKey, navigate, setTabName, tabNames]);

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    const newTabName = tabNames[key] || "Users";

    setTabName(newTabName); // Update breadcrumb

    navigate(`/provider/user-directory/${key}`);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        tabPosition="top"
      >
        <Tabs.TabPane tab="Users" key="1">
          <ProviderUser />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Role" key="2">
          <Role />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default UserDirectoryTabs;
