
import React, { useState, memo, useEffect } from 'react';
import { openLinkInNewTab } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { Alert, Checkbox, Col, Form, Input, notification, Row, Select, Typography } from 'antd';

import logo from "../../assets/svg_images/BM.svg";
import topDesign from "../../assets/svg_images/Group 427319164.svg";
import centerImage from "../../assets/svg_images/Group 427319167.svg";
import sideImage from "../../assets/svg_images/side bar.svg";
import BottomImage from '../../assets/svg_images/Group 427319169.svg';

const roleMapping = {
  'SUPER ADMIN': 'System Administrator',
  'VERIFICATION': 'E&B Data Integrity Specialist',
  'AUDIT': 'E&B Compliance Auditor',
  'ADMIN': 'E&B Administrator',
  'VERIFICATION CALLER': 'E&B Caller & Data Integrity Specialist',
  'AUDIT CALLER': 'E&B Caller - Compliance Auditor',
  'CODING': 'Coding and Reimbursement Specialist',
  'GUEST': 'Visitor Viewer',
};

const getMappedRoleName = (name) => roleMapping[name] || name;

const SignIn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { projectList } = useSelector((state) => state.project) ?? [];
  const { roleList } = useSelector((state) => state.role) ?? [];

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [error, setError] = useState(null);
  const [shake, setShake] = useState(false);

  const [showVideo, setShowVideo] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  const handleVideoEnd = () => {
    setShowVideo(false); // Hide the video and show the Sign-In page
  };

  const handleTimeUpdate = (e) => {
    const videoElement = e.target;
    if (videoElement.duration - videoElement.currentTime <= 0.5) {
      setFadeOut(true); // Start fade-out animation 0.5 seconds before video ends
    }
  };



  useEffect(() => {
    const checkAuthToken = async () => {
      const token = sessionStorage.getItem('rememberToken');

      if (token) {
        dispatch(setLoading(true));

        try {
          const { validateToken } = await import("../../api/signIn/signIn")
          const response = await validateToken(token);

          const result = response?.data;

          if (response?.status === 200 && result?.isValid) {
            if (result?.user?.remember) {
              form.setFieldsValue({
                email: result?.user?.email,
                password: result?.user?.password,
                role: result?.user?.role,
                project: result?.user?.project,
                remember: result?.user?.remember,
              });
            } else {
              sessionStorage.removeItem('rememberToken');
              form.resetFields();
            }
          } else {
            notification.warning({
              message: 'Invalid token or user not authorized.',
              placement: 'topLeft'
            });
            sessionStorage.removeItem('rememberToken');
            navigate('/');
          }
        } catch (error) {
          notification.warning({
            message: 'An error occurred during token validation.',
            placement: 'topLeft'
          });

          sessionStorage.removeItem('rememberToken');
          navigate('/');
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        navigate('/internal');
      }
    };

    checkAuthToken();
  }, [dispatch, form, navigate]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       dispatch(setLoading(true));

  //       const { getProjectsApi } = await import("../../redux/project/projectService")

  //       // Fetch projects
  //       await dispatch(getProjectsApi()).unwrap();

  //       const { getRolesApi } = await import("../../redux/role/roleService")

  //       // Fetch roles
  //       await dispatch(getRolesApi()).unwrap();



  //       dispatch(setLoading(false));
  //     } catch (error) {
  //       dispatch(setLoading(false));
  //       const { axiosErrorHandling } = await import("../../utils/utility")
  //       return axiosErrorHandling(error);
  //     }
  //   };
  //   fetchData();
  // }, [dispatch]);

  // const onChange = (value) => {
  // };

  // const onSearch = (value) => {
  // };

  useEffect(() => {
    form.setFields([
      {
        name: 'project',
        rules: isSuperAdmin ? [] : [{ required: true, message: 'Please select the project!' }],
      },
    ]);
  }, [isSuperAdmin, form]);

  const handleRoleChange = (value) => {
    const selectedRoleIds = value || [];
    const selectedRoles = roleList.filter(role => selectedRoleIds.includes(role.id));
    const superAdminRole = selectedRoles.some(role => role.name === 'SUPER ADMIN');

    setIsSuperAdmin(superAdminRole);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFinish = async (values) => {
    try {
      dispatch(setLoading(true));
      const internalUser = {
        email: values?.email,
        password: values?.password,
        role: values?.role,
        project: values?.project,
        rememberMe: values?.remember ? values?.remember : false
      };

      const { signInApi } = await import("../../api/signIn/signIn")

      const { status, data } = await signInApi(internalUser);

      // Store token in localStorage if "Remember me" is selected
      if (values?.remember) {
        sessionStorage.setItem('rememberToken', data?.token);
      }

      if (data === "No User Found!") {
        setError('Invalid credentials, please verify your email and password');
        setShake(true);
        setTimeout(() => setShake(false), 500);
        dispatch(setLoading(false));
        return;
      }

      if (status === 201) {
        const userId = data.userId;
        const role = data.user?.toUpperCase();
        const project = data.project;
        const timeZone = data.timeZone;
        const userProject = data?.userProject;
        const firstName = data?.firstName;
        const lastName = data?.lastName;
        const projectName = data?.projectName;
        const avatarKey = data?.avatarKey;
        const avatarUrl = data?.avatarUrl;
        const emailId = data?.email;
        const userProjectAccess = data?.userProjectAccess;

        // const roleRedirects = {
        //   "SUPER ADMIN": "/super-admin/home",
        //   ADMIN: "/admin/home",
        //   GUEST: "/admin/home",
        //   PROVIDER: "/provider/home",
        //   USER: "/provider/home",
        //   "VERIFICATION CALLER": "/users/home",
        //   VERIFICATION: "/users/home",
        //   AUDIT: "/users/home",
        //   "AUDIT CALLER": "/users/home",
        //   CODING: "/users/home",
        // };

        const { setUserDetails } = await import('../../redux/userDetails/userDetailsSlice'); // Dynamic import

        dispatch(setUserDetails({ userId, role, project, timeZone, userProject, firstName, lastName, projectName, avatarKey, avatarUrl, emailId, userProjectAccess }));


        if (data?.twoFactorEnabled) {
          dispatch(setLoading(false));
          if (data?.lastOtpVerified) {
            switch (role) {
              case "ADMIN":
              case "GUEST":
                navigate('/admin/home');
                break;
              case "PROVIDER":
                navigate('/provider/home');
                break;
              case "USER":
                navigate('/provider/home');
                break;
              case "SUPER ADMIN":
                navigate('/super-admin/home');
                break;
              case "VERIFICATION CALLER":
              case "VERIFICATION":
              case "AUDIT":
              case "AUDIT CALLER":
              case "CODING":
                navigate('/users/home');
                break;
              default:
                navigate('/');
                break;
            }
          }

          else {
            return navigate('/twoFactor');
          }
        }
        const { trackLogIn } = await import('../../api/userLog/userLog'); // Dynamic import

        await trackLogIn(userId, role)
        switch (role) {
          case "ADMIN":
          case "GUEST":
            navigate('/admin/home');
            break;
          case "PROVIDER":
            navigate('/provider/home');
            break;
          case "USER":
            navigate('/provider/home');
            break;
          case "SUPER ADMIN":
            navigate('/super-admin/home');
            break;
          case "VERIFICATION CALLER":
          case "VERIFICATION":
          case "AUDIT":
          case "AUDIT CALLER":
          case "CODING":
            navigate('/users/home');
            break;
          default:
            navigate('/');
            break;
        }

        dispatch(setLoading(false));
      }

    }

    catch (error) {
      setError(error?.response?.data);
      setError(error?.response?.data);
      setShake(true);
      setTimeout(() => setShake(false), 500);
      dispatch(setLoading(false));
    }

    finally {

      dispatch(setLoading(false));
    }
  };

  return (
    <>
    {showVideo ? 
    (
    // <div className="flex justify-center items-center w-screen h-screen bg-black ">
    <div
          className={`flex justify-center items-center w-screen h-screen bg-black ${
            fadeOut ? 'opacity-0 transition-opacity duration-500' : 'opacity-100'
          }`}
        >
          <video
            src="https://wws-bm-assests.s3.us-east-1.amazonaws.com/WWS_Logo/WWS_BM_Logo_Animation_final.mp4"
            className="w-full h-full object-cover"
            autoPlay
            muted
            onEnded={handleVideoEnd}
            onTimeUpdate={handleTimeUpdate}
          />
        </div>)
      : (<Row gutter={[16, 16]} className="w-full overflow-hidden">

        <Col xs={12} sm={12} md={8} lg={6} xl={8} className="relative">
          <img src={logo} alt="wwsProp" className="w-9 h-12 absolute left-[10%]" />
        </Col>

        <Col xs={24} sm={12} md={8} lg={6} xl={8} className="relative">
          <div className="absolute top-0 -left-[90%]">
            <img
              src={topDesign}
              alt="wwsProp"
              className="w-auto h-auto md:w-[70%] lg:w-[90%] xl:w-[50%] md:translate-x-[20%] lg:translate-x-[100%] xs:translate-x-[20%]"
            />
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={15} xl={15} rowSpan={3}>
          <img
            src={centerImage}
            alt="wwsProp"
            className="w-[80%] md:w-[70%] lg:w-[80%] xl:w-[50%] h-auto translate-y-[30%] md:translate-y-[20%]  left-1/2 translate-x-[30%] center-image center-image-small"
          />
          <div className="absolute top-[60%] md:top-[50%] lg:top-[60%] w-[100%]">
            <img src={sideImage} alt="wwsProp" className="w-auto h-auto md:w-[70%] lg:w-[10%] xl:w-[50%]" />
          </div>
        </Col>

        <Col xs={24} sm={11} md={11} lg={8} xl={8} className="flex items-center justify-center mt-[3%]"
        style={{ marginLeft: '4%' }} 
        >
          <div >
            <h3>
              <p className="text-[#252525] text-sm font-light break-words relative bottom-[1vw]" style={{ fontFamily: "mulish" }}>
                We're glad you're here. Please sign in to access your account.
              </p>
            </h3>

            <div
              className="p-[1%]"
            >
              {error && (
                <Alert
                  message="Login Error"
                  description={error}
                  type="error"

                  className={`mb-[1%] ${shake ? 'animate-shake' : ''}`}
                />
              )}
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                size="large"
                onFinish={onFinish}
                onFieldsChange={(changedFields, allFields) => {
                  const roleField = allFields.find(field => field?.name[0] === 'role');
                  if (roleField) {
                    handleRoleChange(roleField.value);
                  }
                }}
              >
                <Form.Item
                  label={<div className="font-plus-jakarta-sans font-bold text-xs text-black">Email</div>}
                  name="email"
                  labelCol={{ span: 24, style: { marginBottom: '-3%' } }}
                  style={{ marginBottom: '1px' }}
                  
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email!',
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ]}
                >
                  <Input className={`w-[85%] ${shake ? 'animate-shake' : ''}`} placeholder="Enter your email" />
                </Form.Item>

                <Form.Item
                  label={<div className="font-plus-jakarta-sans font-bold text-xs text-black">Password</div>}
                  name="password"
                  labelCol={{ span: 24 , style: { marginBottom: '-3%' }}}
                  style={{ marginBottom: '1px' }}

                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password!',
                    },
                    {
                      min: 6,
                      message: 'Password must be at least 6 characters long!',
                    },
                  ]}
                >
                  <Input.Password
                    className={`w-[85%] ${shake ? 'animate-shake' : ''}`} placeholder="Enter your Password" />
                </Form.Item>

                <Form.Item
                  label={<div className="font-plus-jakarta-sans font-bold text-xs text-black">Facility</div>}
                  name="project"
                  labelCol={{ span: 24, style: { marginBottom: '-3%' } }}
                  rules={isSuperAdmin ? [] : [{ required: true, message: 'Please select the project!' }]}
                  style={{ marginBottom: '1px' }}
                >
                  <Select
                    className={shake ? 'animate-shake' : ''}
                    style={{ "width": "85%" }}
                    showSearch
                    allowClear

                    placeholder="Select Facility"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={filterOption}
                    options={projectList.map(({ name, id }) => ({
                      value: id,
                      label: name,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 , style: { marginBottom: '-3%' }}}
                  label={<div className="font-plus-jakarta-sans font-bold text-xs text-black">Role</div>}
                  name="role"
                  style={{ marginBottom: '5px' }}
                  rules={[{ required: true, message: 'Please select the role!' }]}
                >
                  <Select
                    className={shake ? 'animate-shake' : ''}
                    style={{ "width": "85%" }}
                    showSearch
                    allowClear

                    placeholder="Select User Role"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={filterOption}
                    options={roleList.map(({ name, id }) => ({
                      value: id,
                      label: getMappedRoleName(name),
                    }))}
                  />
                </Form.Item>


                <div className="grid grid-cols-2 grid-rows-1 gap-[18%] ">
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox className="text-black text-[11.53px] font-plus-jakarta-sans font-bold break-words text-start">
                      Remember me
                    </Checkbox>
                  </Form.Item>
                  <Typography
                    onClick={() => navigate("/forGetPassword")}
                    className="text-[#367AFF] text-[11.53px] font-plus-jakarta-sans font-normal break-words cursor-pointer justify-end translate-y-[15%]"
                  >
                    Forgot Password
                  </Typography>
                </div>
                <Form.Item>
                  <button
                    className="cursor-pointer font-plus-jakarta-sans font-[700] text-sm text-white bg-blue-500 py-3 px-12 border-none shadow-lg rounded-lg transition-transform transform duration-1000 w-[85%]"
                    htmltype="submit"
                  >
                    Sign In
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="relative flex flex-col justify-end max-h-screen ">
          <div className="flex flex-col items-center flex-grow">
            <Row gutter={24} className="flex-nowrap">
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/code-of-ethics-wws/')}
                >
                  About Us
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="TermsOfService text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/terms-of-services/')}
                >
                  Terms of Service
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="PrivacyPolicy text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => navigate("/privacyPolicy")}
                >
                  Privacy Policy
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="ContactSuppport text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/get-in-touch/')}
                >
                  Contact Support
                </Typography>
              </Col>
            </Row>
            <div className="WonderWorthSolutionsLlc text-justify text-gray-800 font-semibold font-['Mulish'] tracking-wider mt-4">
              © Wonder Worth Solutions LLC
            </div>
            <div className="VuesaxLinearCopyright w-3.5 h-3.5 relative">
              <div className="Copyright w-3.5 h-3.5 left-0 top-0 absolute"></div>
            </div>
          </div>
          <div
            className="absolute bottom-0 right-0 
          transform 
          translate-x-[-50%] 
          translate-y-[10%]
          md:translate-x-[5%] 
          lg:translate-x-[60%] 
          sm:translate-x-[60%] 
          xs:translate-x-[20%]"
          >
            <img
              src={BottomImage}
              alt="wwsProp"
              className="w-auto 
            h-auto
             md:w-[70%] 
             lg:w-[80%]
              xl:w-[20%]"
            />
          </div>
        </Col>

      </Row >
      )}
    </>
  );
};

export default memo(SignIn);