import React, { useCallback, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { createOrUpdateRecentHistoryApi } from '../redux/recentHistory/recentHistoryService';
import { useDispatch, useSelector } from 'react-redux';

const screenResolutions = {
  'xxl': '1600px',
  'xl': '1200px',
  'lg': '992px',
  'md': '768px',
  'sm': '576px',
  'xs': '400px',
}

const ModalBox = ({
  open,
  title,
  width,
  content,
  onSubmitTitle,
  onSubmit,
  onCancelTitle,
  onCancel,
  onSubmitDisabled,
  isDcSubmitChart
}) => {
  const userId = useSelector((state) => state.userDetails.userId);
  const projectId = useSelector((state) => state.userDetails.project);
  const dispatch = useDispatch();

  const handleCreateOrUpdate = useCallback((historyData) => {
    dispatch(createOrUpdateRecentHistoryApi(historyData));
  }, [dispatch]);

  useEffect(() => {
    const data = content?.props?.ChartDetailView?.data;
    if (data && open) {
      let chartId = data?.id || data?._id;
      handleCreateOrUpdate({ userId, chartId, projectId, isActive: true, timeZone: 'UTC' })
    }
  }, [open, content, handleCreateOrUpdate, userId, projectId]);

  return (
    <Modal
      keyboard={false}
      closable={false}
      open={open}
      width={screenResolutions[width]}
      title={
        <div className="flex justify-between items-center">
          <span>{title}</span>
          <CloseOutlined className="text-xl cursor-pointer" onClick={onCancel} />
        </div>
      }
      centered
      footer={[
        <div key='footer-details' className='w-full flex justify-between items-center'>
          {onCancelTitle && <Button
            type='primary'
            danger
            disabled={isDcSubmitChart ? true : false}
            onClick={onCancel}
          >
            {onCancelTitle}
          </Button>}
          {onSubmitTitle && <Button
            loading={isDcSubmitChart ? isDcSubmitChart : null}
            className='bg-blue-500'
            type='primary'
            key="submit"
            htmlType="submit"
            disabled={onSubmitDisabled ? onSubmitDisabled : false}
            onClick={onSubmit}
          >
            {onSubmitTitle}
          </Button>}
        </div>
      ]}
    >
      <div className='w-full h-full'>
        {content}
      </div>
    </Modal>
  )
}

export default ModalBox;
