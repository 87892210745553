import { Modal } from 'antd'
import React, { Suspense, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';

const PiChart = React.lazy(() => import('./PiChart'));


// const PiChart = React.lazy(() =>
//  new Promise((resolve) => {
//   setTimeout(() => {
//    resolve(import('./BarChart')); // Dynamically import the component after 20 minutes
//   }, 1 * 60 * 1000); // 20 minutes in milliseconds (1,200,000 ms)
//  })
// );

const modalBodyStyle = { padding: 0, height: "100vh" }

const PiChartComponent = ({ transformedData, modal1Open, setModal1Open, completedPercentage, fullScreen }) => {

  const role = useSelector((state) => state.userDetails.role);

  const modalContent = useMemo(() => (
    <Suspense fallback={
      <>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="font-poppins text-center text-black text-opacity-100">
            <span className='font-poppins text-md text-black animate-fadeIn'>
              Hang on! chart's getting dressed<p className="text-4xl font-semibold animate-pulse inline"> . . .</p>
            </span>
          </div>
        </div>
      </>
    }>

      <div className="flex flex-col justify-center items-center w-full h-full">
        <PiChart data={transformedData} fullView={true} />
        {/* Below the chart: Total number of completed charts */}
        <div className="translate-y-[1%] text-[#151D48] font-bold text-center mt-4">

          <div className="text-lg">
            Total No of Charts Completed:
          </div>
          <div className="text-2xl font-extrabold">
            {completedPercentage > 0 ? completedPercentage?.toFixed(2) : 0}%
          </div>
        </div>
      </div>
    </Suspense>

  ), [completedPercentage, transformedData])

  const handleModalToggle = useCallback(() => {
    setModal1Open(false);
  }, [setModal1Open]);

  return (
    <>
      {transformedData?.length > 0 ? (
        <>
          {modal1Open ? (
            <Modal
              title="Full Screen Pi Chart"
              style={{ top: 20, left: 20 }}

              styles={{
                body: modalBodyStyle // Use this instead of bodyStyle
              }}
              open={modal1Open}
              onCancel={handleModalToggle}
              width="100vw" // Full width
              footer={null} // Optional: Hide default footer
              className="fixed w-full h-[90vh] overflow-hidden" // Full screen modal
            >
              {modalContent}
            </Modal>
          ) : null}

          <Suspense fallback={
            <>
              <div className="w-full h-full flex flex-col justify-center items-center">
                <div className="font-poppins text-center text-black text-opacity-100">
                  <span className='font-poppins text-md text-black animate-fadeIn'>
                    Hang on! charts's getting dressed<p className="text-4xl font-semibold animate-pulse inline"> . . .</p>
                  </span>
                </div>
              </div>
            </>
          }>
            <div className='flex items-center justify-items-center'>
              <div className='w-[60%]'>
                <PiChart data={transformedData} />
              </div>
              <div className='translate-y-[30%] text-[#151D48] font-bold'>

                {/* <div className='mq825:w-[100%] flex items-center w-[100%]  mq825:flex mq825:items-center mq825:relative mq825:right-5  1024:w-[100%] 1024:ms-2 1366:w-[70%] mq675:ms-3 mq675:w-[60%] mq1050:relative mq1050:right-6'> */}
                <div className={`mq825:w-[100%] flex items-center w-[100%]  mq825:flex mq825:items-center mq825:relative mq825:right-5  1024:w-[100%] 1024:ms-2 1366:w-[70%] mq675:ms-3 mq675:w-[60%] mq1050:relative mq1050:right-6 ${role === "PROVIDER" ? "provider-specific" : ""} ${role === "PROVIDER" ? "mini-text-move" : ""} ${role === "PROVIDER" ? "micro-text-move" : ""}`}>
                  Total No of Charts Completed:
                  {/* <div className='text-center font-extrabold text-lg ps-2 mq825:relative mq825:right-28 mq825:top-2 mq675:relative mq675:left-2 mq1050:relative mq1050:right-16'>*/}
                  <div className={`text-center font-extrabold text-lg ps-2 mq825:relative mq825:right-28 mq825:top-2 mq675:relative mq675:left-2 mq1050:relative mq1050:right-16 ${role === "PROVIDER" ? "provider-specific-container" : ""} ${role === "PROVIDER" ? "move-value" : ""} ${role === "PROVIDER" ? "mini-percentage-move" : ""} ${role === "PROVIDER" ? "micro-percentage-move" : ""}`}>
                    {completedPercentage > 0 ? completedPercentage?.toFixed(2) : 0}%
                  </div>
                </div>
              </div>
            </div>
          </Suspense>
          <img
            src={fullScreen}
            alt="fullScreen"
            className="absolute top-2 right-2 z-10 cursor-pointer"
            onClick={() => setModal1Open(true)}
          />

        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <div className="animate-fadeIn font-poppins  text-center text-gray-500">
            <p className="text-xl font-semibold">No Data Available</p>
            <p className="text-sm">Please select a different filter or try again later.</p>
          </div>
        </div>
      )}
    </>
  )
}


export default React.memo(PiChartComponent)
