import { useState } from "react";

const useResponsiveState = () => {
  const mediaQuery = "(max-width: 768px)";
  const isMobile = window.matchMedia(mediaQuery).matches;

  // Properly initialize `collapsed` and `setCollapsed`
  const [collapsed, setCollapsed] = useState(isMobile);

  return { isMobile, collapsed, setCollapsed };
};

export default useResponsiveState;
