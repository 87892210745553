import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { useEffect } from 'react';
import SuspenseLoading from '../../components/SuspenseLoading';

const AppGuard = ({ children }) => {
  const role = useSelector((state) => state.userDetails.role);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      try {
        // Call the backend to validate the token
        const { checkAuth } = await import('../../api/validateAuth/validAuth')
        const response = await checkAuth()

        if (response.status === 200) {
          const { logIn } = await import("../../redux/auth/authSlice")
          dispatch(logIn()); // Token is valid, log in the user
        }
      } catch (error) {
        // Extract error title and description from backend
        const backendTitle = error?.response?.data?.title || 'Unauthorized Access';
        const backendDescription = error?.response?.data?.description || 'Unknown error occurred';
        // Handle invalid or missing token
        const { logOut } = await import("../../redux/auth/authSlice")
        dispatch(logOut()); // Dispatch logOut action

        switch (role) {
          case "ADMIN":
          case "GUEST":
          case "VERIFICATION CALLER":
          case "VERIFICATION":
          case "AUDIT":
          case "AUDIT CALLER":
          case "CODING":
          case "SUPER ADMIN":
            navigate("/internal");
            break;
          default:
            navigate("/");
            break;
        }

        const { clearUserDetails } = await import('../../redux/userDetails/userDetailsSlice')
        // Perform logout actions
        dispatch(clearUserDetails());
        // Show notification
        notification.error({
          message: backendTitle,
          description: backendDescription,
          placement: 'topRight',
          duration: 2,
        });
        // Redirect to login page
      }
    };

    validateToken();
  }, [dispatch, navigate, role]);

  // While validating, show a loading spinner or placeholder
  if (isAuthenticated === null) {
    return <SuspenseLoading />;
  }

  // If not authenticated, block rendering children
  if (!isAuthenticated) {
    return null;
  }

  // Render children if authenticated
  return children;
};

export default AppGuard;
