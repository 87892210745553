import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  openLinkInNewTab,
} from '../../utils/utility.js';
import logo from "../../assets/svg_images/BM.svg";
import topDesign from "../../assets/svg_images/Group 427319164.svg";
import centerImage from "../../assets/svg_images/Group 427319167.svg";
import sideImage from "../../assets/svg_images/side bar.svg";
import BottomImage from '../../assets/svg_images/Group 427319169.svg';
import { setLoading } from '../../redux/loading/loadingSlice.js';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [shake, setShake] = useState(false);
  const { userId } = useSelector((state) => state.userDetails);

  const [showVideo, setShowVideo] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  const handleVideoEnd = () => {
    setShowVideo(false); // Hide the video and show the Sign-In page
  };

  const handleTimeUpdate = (e) => {
    const videoElement = e.target;
    if (videoElement.duration - videoElement.currentTime <= 0.5) {
      setFadeOut(true); // Start fade-out animation 0.5 seconds before video ends
    }
  };

  useEffect(() => {
    const checkAuthToken = async () => {
      const token = localStorage.getItem('rememberToken');

      if (token) {
        dispatch(setLoading(true));

        try {
          const { validateToken } = await import('../../api/provider/providerUsers')
          const response = await validateToken(token);

          const result = response?.data;

          if (response.status === 200 && result.isValid) {
            if (result?.user?.remember) {
              form.setFieldsValue({
                email: result?.user?.email,
                password: result?.user?.password,
                remember: result?.user?.remember,
              });
            } else {
              form.resetFields();
            }
          } else {
            notification.warning({
              message: 'Invalid token or user not authorized.',
              placement: 'topLeft'
            });
            localStorage.removeItem('rememberToken');
            navigate('/');
          }
        } catch (error) {

          console.error('Error:', error);

          notification.warning({
            message: 'An error occurred during token validation.',
            placement: 'topLeft'
          });

          localStorage.removeItem('rememberToken');
          navigate('/');
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        navigate('/');
      }
    };

    checkAuthToken();
  }, [dispatch, form, navigate]);

  const onFinish = async (values) => {
    try {
      dispatch(setLoading(true));
      const providerUser = {
        email: values.email,
        id: userId,
        password: values.password,
        rememberMe: values.remember ? values.remember : false,
        role: null
      };
      const { signInApi } = await import('../../api/signIn/signIn.js');

      const { status, data } = await signInApi(providerUser);



      const userData = data;

      if (status === 201 && userData?.isActive && userData?.status !== "SUSPEND") {
        const { setUserDetails } = await import('../../redux/userDetails/userDetailsSlice');

        dispatch(setUserDetails({

          userId: userData?.userId,
          role: userData?.isProvider ? "PROVIDER" : "USER",
          project: userData?.project,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          projectName: userData?.projectName,
          userAccess: userData?.userAccess,
          timeZone: userData?.timeZone,
          avatarKey: userData?.avatarKey,
          avatarUrl: userData?.avatarUrl,
          emailId: userData?.email

        }));



        // Store token in localStorage if "Remember me" is selected
        if (values.remember) {
          sessionStorage.setItem('rememberToken', data?.token);
        }

        if (userData?.twoFactorEnabled && userData?.isActive) {
          dispatch(setLoading(false));
          localStorage.setItem('cameFromSignIn', 'true');


          if (userData?.lastOtpVerified) {

            return navigate("/provider/home");
          }
          else {

            return navigate("/twoFactor");
          }
        } else {
          dispatch(setLoading(false));

          if (!userData?.findUser) {
            dispatch(setLoading(false));
            const userName = userData?.firstName + " " + userData?.lastName
            localStorage.setItem("Name", userName)
            localStorage.setItem("timeZones", userData?.timeZone?.value)
            localStorage.setItem("email", userData?.email)
            localStorage.setItem("jobTitle", userData?.jobTitle)
            return navigate(`/policy/${userData?.userId}`)
          }

          return navigate("/provider/home");
        }

      }
      if (status === 201 && (!userData?.isActive || (userData?.isActive && userData?.status === "SUSPEND"))) {
        notification.warning({
          message: 'Please contact your administrator as you are unable to log in!',
          placement: 'topLeft'
        });
        dispatch(setLoading(false));
      }

      if (data === "No User Found!") {
        setError('Invalid credentials, please verify your email and password');
        setShake(true);
        setTimeout(() => setShake(false), 500);
        dispatch(setLoading(false));
      }

    } catch (error) {
      setError(error?.response?.data);
      setShake(true);
      setTimeout(() => setShake(false), 500);
      dispatch(setLoading(false));
    }
  };

  return (
    <>
    {showVideo ? (
        <div
          className={`flex justify-center items-center w-screen h-screen bg-black ${
            fadeOut ? 'opacity-0 transition-opacity duration-500' : 'opacity-100'
          }`}
        >
          <video
            src="https://wws-bm-assests.s3.us-east-1.amazonaws.com/WWS_Logo/WWS_BM_Logo_Animation_final.mp4"
            className="w-full h-full object-cover"
            autoPlay
            muted
            onEnded={handleVideoEnd}
            onTimeUpdate={handleTimeUpdate}
          />
        </div>
      ) :
      (<Row gutter={[16, 16]} className="w-full overflow-hidden">
        <Col xs={12} sm={12} md={8} lg={6} xl={8} className="relative">
          <img src={logo} alt="wwsProp" className="w-9 h-12 absolute left-[10%]" />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={8} className="relative">
          <div className="absolute top-0 -left-[45%]">
            <img
              src={topDesign}
              alt="wwsProp"
              className="w-auto h-auto md:w-[70%] lg:w-[90%] xl:w-[50%] md:translate-x-[20%] lg:translate-x-[20%] xs:translate-x-[20%]"
            // className="w-auto h-auto md:w-[70%] lg:w-[70%] xl:w-[50%] md:translate-x-[20%] lg:translate-x-[200%] xs:translate-x-[20%]"
            />
          </div>
        </Col>

        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>

        <Col xs={24} sm={24} md={12} lg={14} xl={15} rowSpan={3}>
          <img
            src={centerImage}
            alt="wwsProp"
            className="w-[80%] md:w-[70%] lg:w-[80%] xl:w-[50%] h-auto translate-y-[30%] md:translate-y-[20%]  left-1/2 translate-x-[30%] center-image-provider"
          // className="w-[80%] md:w-[70%] lg:w-[70%] xl:w-[50%] h-auto translate-y-[30%] md:translate-y-[20%] lg:translate-y-[8%] left-1/2 translate-x-[30%]"
          />
          <div className="absolute top-[60%] md:top-[50%] lg:top-[70%] w-[100%]">
            <img src={sideImage} alt="wwsProp"
              className="w-auto h-auto md:w-[70%] lg:w-[10%] xl:w-[50%]" />
          </div>
        </Col>

        <Col xs={24} sm={24} md={11} lg={9} xl={8} className="flex items-center justify-center mb-[-1%] ml-[4.1%]"

          style={{ marginTop: '2.5%' }}

        >
          <div className="text-[#252525] text-sm font-mulish font-light break-words">
            <h3>
              <p className="text-[#252525] text-sm font-mulish font-light break-words">
                We're glad you're here. Please sign in to access your account.
              </p>
            </h3>


            <div className="p-[5%]">
              {error && (
                <Alert
                  message="Login Error"
                  description={error}
                  type="error"
                  showIcon
                  className={`mb-4 ${shake ? 'animate-shake' : ''}`}
                />
              )}
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                size="large"
                onFinish={onFinish}
              >
                <Form.Item
                  label={<div className="font-plus-jakarta-sans font-bold text-xs-5 text-black">Email</div>}
                  name="email"
                  labelCol={{ span: 24, style: { marginBottom: '0%' } }}
                  style={{ marginBottom: '4px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email!',
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                  ]}
                >
                  <Input className={`w-[85%] ${shake ? 'animate-shake' : ''}`} placeholder="Enter your email" />
                </Form.Item>

                <Form.Item
                  label={<div className="font-plus-jakarta-sans font-bold text-xs-5 text-black">Password</div>}
                  name="password"
                  labelCol={{ span: 24, style: { marginBottom: '0%' } }}
                  style={{ marginBottom: '1px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password!',
                    },
                    {
                      min: 6,
                      message: 'Password must be at least 6 characters long!',
                    },
                  ]}
                >
                  <Input.Password className={`w-[85%] ${shake ? 'animate-shake' : ''}`} placeholder="Enter your Password" />
                </Form.Item>
                <div className="grid grid-cols-2 grid-rows-1 gap-[15%] ">
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox className="text-black text-[11.53px] font-plus-jakarta-sans font-bold break-words text-start">
                      Remember me
                    </Checkbox>
                  </Form.Item>
                  <Typography
                    onClick={() =>
                      navigate('/forGetPassword', {
                        state: { from: window.location.pathname }, // Passing current path as the previous URL
                      })
                      // navigate("/forGetPassword")
                    }
                    className="text-[#367AFF] text-[11.53px] font-plus-jakarta-sans font-normal break-words cursor-pointer justify-end translate-y-[15%]"
                  >
                    Forgot Password
                  </Typography>
                </div>
                <Form.Item>
                  <button
                    className="cursor-pointer font-plus-jakarta-sans font-[700] text-sm text-white bg-blue-500 py-3 px-12 border-none shadow-lg rounded-lg transition-transform transform duration-1000 w-[85%]"
                    htmltype="submit"
                  >
                    Sign In
                  </button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col >

        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>
        <Col xs={24} sm={12} md={8} lg={3.42} xl={8}></Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="relative flex flex-col justify-end max-h-screen mt-max-100vh">
          <div className="flex flex-col items-center flex-grow">
            <Row gutter={24} className="flex-nowrap">
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/code-of-ethics-wws/')}
                >
                  About Us
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="TermsOfService text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/terms-of-services/')}
                >
                  Terms of Service
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="PrivacyPolicy text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => navigate("/privacyPolicy")}
                >
                  Privacy Policy
                </Typography>
              </Col>
              <Col span={6} className="flex justify-center items-center whitespace-nowrap">
                <Typography
                  className="ContactSuppport text-justify text-[#357aff] font-semibold font-['Mulish'] tracking-wider cursor-pointer"
                  onClick={() => openLinkInNewTab('https://wonderws.com/get-in-touch/')}
                >
                  Contact Support
                </Typography>
              </Col>
            </Row>
            <div className="WonderWorthSolutionsLlc text-justify text-gray-800 font-semibold font-['Mulish'] tracking-wider mt-4">
              © Wonder Worth Solutions LLC
            </div>
            <div className="VuesaxLinearCopyright w-3.5 h-3.5 relative">
              <div className="Copyright w-3.5 h-3.5 left-0 top-0 absolute"></div>
            </div>
          </div>
          <div
            className="absolute bottom-0 right-0 
          transform 
          translate-x-[-50%] 
          translate-y-[10%]
          md:translate-x-[5%] 
          lg:translate-x-[60%] 
          sm:translate-x-[60%] 
          xs:translate-x-[20%]"
          >
            <img
              src={BottomImage}
              alt="wwsProp"
              className="w-auto 
            h-auto
             md:w-[70%] 
             lg:w-[80%]
              xl:w-[20%]"
            />
          </div>
        </Col>
      </Row >)}
    </>
  );
};

export default SignIn;
