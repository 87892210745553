// redux/slices/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedOut: false, // Tracks whether the user is logged out
    isAuthenticated: null, // Tracks whether the user is authenticated
  },
  reducers: {
    logOut: (state) => {
      state.isLoggedOut = true;
      state.isAuthenticated = false;
    },
    logIn: (state) => {
      state.isLoggedOut = false;
      state.isAuthenticated = true;
    },
    setAuthenticationInProgress: (state) => {
      state.isAuthenticated = null; // While authentication is being validated
    },
  },
});

export const { logOut, logIn, setAuthenticationInProgress } = authSlice.actions;

export default authSlice.reducer;
